import React, {useRef, useState} from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Slider from "react-slick";
import Modal from "react-modal";
import "slick-carousel/slick/slick.scss";
import "../sass/norihall.scss";
import Header from "../components/header.js";

Modal.setAppElement(`#___gatsby`);

export const query = graphql`
	query GalleryQuery($uid : String!){
		prismic {
	    gallery(uid: $uid, lang: "en-us") {
	    	title
	      photographs {
	        fullSize: photograph {
	          ... on PRISMIC_Photograph {
	            title
	            year
	            image
	            imageSharp {
	              childImageSharp {
	                fluid(maxWidth: 1600, quality: 90, webpQuality: 90, sizes: "85%") {
	                  ...GatsbyImageSharpFluid_withWebp
	                }
	              }
	            }
	            _meta {
	              uid
	            }
	          }
	        }
	        thumbnailSize: photograph {
	          ... on PRISMIC_Photograph {
	            title
	            year
	            image
	            imageSharp {
	              childImageSharp {
	                fluid(maxWidth: 540, maxHeight: 540, cropFocus: CENTER, fit: COVER, sizes: "calc( 50% - 2rem ), (min-width: 768px) calc( 50% - 4rem ), (min-width: 992px) calc( 25% - 4rem )") {
	                  ...GatsbyImageSharpFluid_withWebp
	                }
	              }
	            }
	            _meta {
	              uid
	            }
	          }
	        }
	      }
	    }
	  }
  }
`



function Gallery({ data }) {

	const gallerySlider = useRef(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [slideIndex, setSlideIndex] = useState(0);

	const slickSettings = {
		dots: false,
		arrows: false,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		beforeChange: (current, next) => setSlideIndex(next)
	};
	const galleryData = data.prismic.gallery;
	const galleryPhotosFull = galleryData.photographs.map(function(photograph, index) {
		const photoURLSharpFull = photograph.fullSize.imageSharp.childImageSharp.fluid;
		const photoTitle = photograph.fullSize.title[0].text;
		const photoYear = photograph.fullSize.year;
		return (
	    <div className="photo-slide" key={index}>
	    	<div className="photo-slide--wrapper">
			    <Img 
			    	fluid={photoURLSharpFull} 
			    	 alt={photograph.fullSize.image.alt}
		    	/>
					<h3 className="photo-title">{photoTitle}</h3>
					<div className="photo-year">{photoYear}</div>
				</div>
	    </div>
		)
	})
	const galleryPhotosThumbnails = galleryData.photographs.map(function(photograph, index) {
		const photoURLSharpThumbnail = photograph.thumbnailSize.imageSharp.childImageSharp.fluid;
		return (
	    <li key={index} onClick={() => thumbnailClick(index)}><Img fluid={photoURLSharpThumbnail} alt={photograph.fullSize.image.alt} /></li>
		)
	})
  const modalCloseTimeout = 300;
  const closeModal = () => {
    setModalOpen(false);
  };
  const openModal = () => {
    setModalOpen(true);
  };
  const modalStyles = {
	  overlay: {
	    backgroundColor: "rgba(222, 222, 222, 0.95)",
	    zIndex: 2000,
	  },
	  content: {
	    position: "relative",
	    top: "auto",
	    left: "auto",
	    right: "auto",
	    bottom: "auto",
	    width: "85%",
	    maxWidth: "calc(1920px - 4rem)",
	    margin: "2rem auto",
	    padding: "2%",
	    border: 0,
	    borderRadius: 0,
	  },
	};


	function thumbnailClick(index) {
		setSlideIndex(index);
		openModal();
	}

	function updateSlide() {
		gallerySlider.current.slickGoTo(slideIndex);
	}

	return (
		<div>
			<Header></Header>
      <div id="content">
				<h1>{data.prismic.gallery.title}</h1>
				<ul className="gallery singleGallery">
					{galleryPhotosThumbnails}
				</ul>

				<Modal
					isOpen={modalOpen}
					onRequestClose={closeModal}
					style={modalStyles}
					contentLabel="Modal"
					closeTimeoutMS={modalCloseTimeout}
					onAfterOpen={updateSlide}
				>
					<div id="modalClose">
						<button
			        aria-label="close modal"
			        onClick={(e) => {
			          e.preventDefault();
			          closeModal();
			        }}
			      >
			        Close
			      </button>
		      </div>
					<Slider ref={gallerySlider}  {...slickSettings}>
						{galleryPhotosFull}
					</Slider>
				</Modal>
			</div>
		</div>
	)
	
}

export default Gallery
